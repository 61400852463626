/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : MediaQuery 320
    Author     : Rizki Nida Chaerulsyah - akuiki.net
==============================================================================================*/


/* structure
----------------------------------------------------------------------------------------------*/

.all-content {
    padding: 58px 0 0;
}


/* header
----------------------------------------------------------------------------------------------*/

header {
    height: 59px;
}

header .header-container {
    margin: 0;
    padding: 0 20px 0 0px;
}

header .left.show-customer {
    display: block;
    left: 70px;
    top: 7px;
    position: relative;
}

header .left:after {
    display: none;
}

header .left {
    display: none;
    margin: 4px 15px 0 0;
}

header .left a .text {
    display: inline-block;
    width: 0;
    text-indent: -999px;
}

header .left:first-child a {
    margin-left: 38px
}

header .left.lang {
    display: none;
}

header .left:after {
    right: -6px;
}

header .logo {
    width: 165px;
}

aside {
    position: absolute;
    width: 100%;
    height: 45px;
    top: 31px;
    left: -30px;
    display: block;
    bottom: auto;
}

aside nav a.toggle-menu {
    top: -60px;
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
}

aside nav a.toggle-menu.active {
    z-index: 6;
}

aside nav a.toggle-menu.active span {
    background: #ffff;
}

aside nav a {
    border: none;
}

aside nav a .ico {
    width: 45px;
    height: 45px;
    background-size: 200% auto !important;
}

aside nav a:last-child {
    border: none;
}

aside nav a .box-hover {
    right: 0;
    padding: 0;
}

aside nav a .box-hover .inner-hover {
    right: 0;
    left: 0;
    width: 45px;
    height: 45px;
    margin: auto;
}

aside nav a.active .box-hover {
    height: 100%;
    height: 4px;
    width: 100%;
    padding: 0 0 0 2px;
    left: -2px;
    bottom: -46px;
}

.menu-content {
    margin: 0px 0px 0 0px;
    padding: 0;
    width: 100%;
    z-index: 5
}

.menu-content .right {
    display: none;
}

.menu-content .left {
    width: 100%;
}

.menu-content .left .search,
.menu-content .left .box-menu {
    padding: 0 25px 0 0px;
}

.menu-content .left .search {
    margin: 100px 0 20px 0;
}

.menu-content .logo {
    width: 165px;
    top: 15px;
    right: 20px;
    z-index: 1;
}

.menu-content .left .search input[type=text] {
    width: 100%;
}

.grid-view .box.slider-wrap .text h2 {
    font-size: 32px;
}

.grid-view .box--column.news .text {
    bottom: 45px;
}

.grid-view .box.slider-wrap.slider-img-text .text {
    display: none;
}

.grid-view .box.slider-wrap.slider-img .image img,
.grid-view .box.slider-wrap.slider-img-text .image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: top;
    margin: auto;
}

.grid-view .box.slider-wrap .bx-wrapper .bx-pager.bx-default-pager {
    display: block;
    bottom: 10px;
}

.grid-view .box.slider-wrap .text {
    bottom: 30px;
}

.grid-view .box.slider-wrap .text p {
    margin-bottom: 10px;
}

.grid-view .box.slider-wrap .text h2 {
    margin-bottom: 15px;
}

.form_apply .row .col.right {
    float: left;
}

.inner-content .form_contact .row .div_check span {
    display: block;
}

.inner-content .box-testi .text {
    width: 100%;
}

.inner-content .box-testi {
    margin: 0 0 35px;
}

.inner-content.news img {
    width: auto !important;
    height: auto !important;
}


/* middle
----------------------------------------------------------------------------------------------*/

.grid-view .left .box,
.grid-view .box {
    width: 100%;
}

.grid-view .box .image {
    height: 100%;
}

.grid-view .box .image img {
    min-height: 100%;
    min-width: 100%;
}

.grid-view .box a {
    display: none;
}

.grid-view .box--grid .box--column.callus a {
    display: block;
}

.grid-view .box.slider-wrap a {
    display: inline-block;
}

.grid-view .box.slider-wrap .bx-wrapper,
.grid-view .box.slider-wrap .bx-viewport {
    max-height: 100%;
}

.grid-view .box--column.kontak-media a {
    display: block;
}

.grid-view.news .box--column.laporan {
    display: block;
}

.grid-view .box--grid .box--column.fund.testi .pager-info {
    bottom: 29.5%;
}

.grid-view .box--grid .box--column.fund .text-money span {
    line-height: 16px;
}

.content .content-wrapper {
    width: 100%;
}

.content .title-page .ico {
    width: 44px;
    height: 44px;
    margin-right: 10px;
    background-size: auto 20px;
}

.content .title-page h1,
.content .title-page h6 {
    font-size: 14px;
}

.content .banner {
    height: 215px;
}

.content .banner img {
    max-height: 100%;
}

.inner-content h2 {
    font-size: 22px;
    line-height: 24px;
}

.inner-content h4 {
    font-size: 16px;
    line-height: 18px;
}

.inner-content h4:after {
    width: 50px;
    margin: 36px 0 20px;
}

.inner-content h5 {
    font-size: 16px;
    margin: 0 0 10px;
}

.inner-content .grid-view .box a {
    display: block;
}

.inner-content .grid-view .box--grid .box--column.callus h5 {
    font-size: 16px;
    font-weight: normal;
}

.inner-content .address_our .list_address {
    width: 100%;
    float: none;
    margin-bottom: 20px;
}

.inner-content .address_our .list_address h6 {
    font-size: 14px;
    text-transform: uppercase;
}

.inner-content .address_our .list_address p {
    font-size: 14px;
    line-height: 18px;
}

.inner-content .address_our .list_address p.ic-telp {
    padding-top: 2px;
    padding-bottom: 2px;
}

.inner-content .address_our .list_address p.ic-fax {
    padding-top: 2px;
    padding-bottom: 2px;
}

#map_contact {
    height: 200px;
}

.inner-content .form_contact .row label {
    font-size: 14px;
}

.inner-content .form_contact .row label.ket {
    font-size: 12px;
}

.inner-content .form_contact .row:first-child .cover-combo {
    margin-bottom: 10px;
}

.inner-content .form_contact .row:first-child input[type="text"],
.inner-content .form_contact .row:nth-child(3) input[type="text"],
.inner-content .form_contact .row textarea {
    width: 230px;
    font-size: 14px;
}

.inner-content .form_contact .row:nth-child(2) input[type="text"] {
    margin-bottom: 10px;
}

.inner-content .form_contact .row .div_check span {
    margin-right: 0;
    font-size: 13px;
}

.inner-content .form_contact .row .div_check span:first-child {
    margin-bottom: 10px;
}

.inner-content .form_contact .row:nth-child(4) select {
    width: 254px;
    font-size: 14px;
}

.inner-content.career .top_src .col:first-child,
.inner-content.career .top_src .col {
    margin: 0 0 10px;
}

.inner-content.career .top_src select {
    width: 258px;
}

.inner-content.career .pagging a {
    width: 45px;
    height: 45px;
    line-height: 45px;
}

.inner-content.career .top_src .cover-combo {
    background-position: 95% center;
}

.form_apply .row {
    margin-bottom: 0px;
}

.form_apply .row .col {
    margin-bottom: 10px
}

.form_apply .row .col label {
    font-size: 14px;
    margin: 0 0 5px 0;
}

.form_apply .row .col.full input[type="text"] {
    width: 242px;
}

.input_file,
.form_apply .row .col input[type="text"] {
    width: 242px;
}

.content .title-page .nav {
    width: 44px;
    top: 44px;
}

.content .title-page .nav a {
    width: 44px;
    height: 44px;
    background-size: 88px !important;
}

.filter-tahun {
    margin: 0px 0 25px 0;
}

.filter-tahun label {
    font-size: 14px;
    margin: 0 5px 0 0;
}

.filter-tahun input[type=text] {
    width: 210px;
    font-size: 14px;
    background-position: 222px center;
    margin: 0 0 10px;
}

.filter-tahun a.print {
    font-size: 14px;
}

.price {
    border-top: 5px solid #212121;
}

.price thead {
    display: none;
}

.price tbody td:nth-child(1),
.price tbody td:nth-child(2),
.price tbody td:nth-child(3),
.price tbody td {
    position: relative;
    display: block;
    font-size: 12px;
    border-right: none;
    text-align: left !important;
    padding: 6px 15px 6px 102px;
}

.price tbody td:before {
    content: "";
    display: block;
    height: 100%;
    top: 6px;
    left: 15px;
    position: absolute;
    font-weight: normal;
}

.price tbody td:nth-child(1):before {
    content: "Unit Link"
}

.price tbody td:nth-child(2):before {
    content: "Mata Uang"
}

.price tbody td:nth-child(3):before {
    content: "Harga"
}

.kantor_map #map_kantor {
    height: 200px;
}

.kantor_map select {
    height: 45px;
    width: 254px;
    font-size: 14px;
}

.kantor_map .cover-combo {
    background: #0076bd url(../images/material/arr-white.png) no-repeat 92% center;
    height: 45px;
}

.kantor_map .inner-combo {
    line-height: 45px;
    font-size: 16px;
}

.list-box .box {
    width: 251px;
    height: 200px;
}

.list-box .box p {
    font-size: 12px;
    line-height: 14px;
}

.list-box .box h6 {
    font-size: 14px;
    line-height: 18px;
}

.list-box .box .text {
    padding: 40px 20px 0;
}

.wrap-popup .box-popup.about p,
.wrap-popup .box-popup.about ul li,
.inner-content table,
.inner-content ul li,
.inner-content ol li,
.inner-content p {
    font-size: 14px;
    line-height: 18px;
}

.list-rekanan .box h3 {
    font-size: 16px;
    line-height: 18px;
    padding: 5px 15px;
}

.list-rekanan .box {
    padding: 10px 0;
}

.list-rekanan .box .desc {
    padding: 15px 0 10px 20px;
}

.inner-content .wrap-tab .nav-tab {
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.nav-tab select,
.nav-tab .cover-combo {
    width: 223px;
    padding: 0;
    height: 38px;
    border: 1px solid #d8d8d8;
    background: #FFF url('../images/material/arr_select.png') no-repeat 90% center;
}

.nav-tab .inner-combo {
    padding: 0 30px 0 15px;
    font-size: 14px;
    overflow: hidden;
    line-height: 38px;
    color: #999;
}

.bar-filter .dropdown select {
    width: 228px;
}

.bar-filter .search input,
.bar-filter .dropdown .cover-combo,
.bar-filter .dropdown select,
.bar-filter .dropdown .inner-combo,
.bar-filter {
    height: 43px;
    line-height: 43px;
    font-size: 12px;
    background-size: 16px auto !important;
}

.bar-filter {
    display: flex;
    flex-direction: row;
}

.bar-filter .dropdown .cover-combo {
    background-position: 10px center;
}

.bar-filter .dropdown .inner-combo {
    margin: 0 30px 0 38px;
}

.bar-filter .search {
    position: absolute;
    right: 45px;
    border-left: 1px solid #ccc;
    background: #999
}

.bar-filter .search input {
    width: 0;
    padding: 0 0 0 45px;
    background: url(../images/material/search-white-ico.png) no-repeat 14px center;
}

.inner-content .image.offset {
    margin: 40px 0px 40px 0px;
}

.inner-content #map_contact.image.offset {
    margin: 40px -40px 40px -55px;
}

.inner-content .image i {
    display: none;
}

.bar-filter .dropdown .cover-combo:after {
    display: none;
}

.bar-filter .dropdown.right select {
    width: 45px;
}

.bar-filter .dropdown.right .cover-combo {
    background: url(../images/material/sort-ico.png) no-repeat 15px center;
    border-left: 1px solid #CCC;
}

.inner-content.product-detail table tbody {
    display: block;
    ;
}

.inner-content.product-detail table {
    width: auto;
    display: block;
}

.inner-content.product-detail table tr {
    display: block;
    position: relative;
}

.inner-content.product-detail table tr th,
.inner-content.product-detail table tr td {
    display: block;
    width: auto;
}

.inner-content.product-detail table tr td {
    padding-top: 0;
}

.inner-content.product-detail table tr th {
    padding-bottom: 5px;
}

.inner-content.product-detail table tr td:nth-child(2) {
    position: absolute;
    top: 15px;
    right: 10px;
}

.inner-content ul.col-3 li,
.inner-content ul.col-3 li:nth-child(3n) {
    margin-right: 0;
}

.grid-view.news .box.slider-wrap span.date {
    font-size: 12px;
}

.grid-view.news .box.slider-wrap .text h2 {
    font-size: 20px;
    line-height: 24px;
}

.grid-view.news .box.slider-wrap .text {
    bottom: 0;
}

.wrap-popup .box-popup {
    margin: 0;
}

.box-popup.product {
    width: 100%;
}

.box-popup.product .desc {
    padding: 25px 20px 30px;
}

.box-popup.product .desc h6 {
    font-size: 14px;
}

.box-popup.product .desc h2 {
    font-size: 24px;
    margin-bottom: 15px;
}

.box-popup.product .desc p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
}

.wrap-popup .box-popup .close {
    width: 44px;
    height: 44px;
}

.wrap-popup .box-popup .close:after {
    background-size: 100% auto !important;
}

.wrap-popup .box-popup.about {
    width: 100%;
    padding: 60px 0 35px;
}

.wrap-popup .box-popup.about .image {
    margin-bottom: 20px
}

.wrap-popup .box-popup.about .image,
.wrap-popup .box-popup.about .desc {
    margin-right: 20px;
    margin-left: 20px;
}

.wrap-popup .box-popup.about h2 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 15px;
}

.wrap-popup .box-popup.about h6 {
    font-size: 14px;
}

.wrap-popup .box-popup.about p {
    margin-bottom: 10px;
}

.wrap-popup .box-popup.about:before {
    height: 30%;
}

.inner-content .list-direksi .box-title,
.inner-content .list-direksi .box {
    /*width: 252px; */
    width: 100%;
    min-height: 308px;
}

.inner-content .list-direksi .box.active .box-blue {
    display: none !important;
}

.inner-content .list-direksi .box .text {
    z-index: 5 !important;
    opacity: 1 !important;
    margin-left: 0 !important;
}

.inner-content .list-direksi .box .desc {
    margin-left: 0 !important;
    width: auto;
}

.inner-content .list-direksi .box .collapse {
    display: none !important;
}

.inner-content .list-direksi .box .desc p {
    padding: 20px 15px 0;
}

.inner-content .list-direksi .box .desc a {
    display: inline-block !important;
    color: #0076bd;
    position: static;
    margin: 0 15px 0;
    padding: 35px 30px 45px 0;
    background: url(../images/material/arr-blue.png) no-repeat right 34px;
}

.inner-content .list-direksi {
    position: relative;
    margin-top: 30px;
}

.inner-content .list-direksi .box .box-blue,
.inner-content .list-direksi .box .image {
    height: 308px;
}

.inner-content .list-direksi .box .text {
    width: 85%;
    left: 24px;
}

.inner-content .list-direksi .box .text h3 {
    font-size: 22px;
}

.form-message .col {
    width: 100%;
    margin: 0;
}

.form-message .col:last-child {
    margin-top: 20px;
}

.inner-content .qoutes {
    font-size: 16px;
    line-height: 18px;
}

.tittle-slider {
    margin: 20px 0;
}

.tittle-slider h3 {
    font-size: 16px;
    line-height: 20px;
}

.form-message h3 {
    font-size: 16px;
    line-height: 20px;
}

.form-message label {
    font-size: 16px;
}

.form-message {
    padding: 50px 0 70px 0;
}

.box-rekomendasi h3 {
    font-size: 16px;
}

.btn-readmore {
    font-size: 14px;
}

.box-rekomendasi .row {
    margin-bottom: 50px;
}

.box-rekomendasi .row .col {
    float: none;
    width: auto;
    margin: 0;
    margin-bottom: 20px;
}

.box-rekomendasi .row:nth-child(2n+2) {
    flex-flow: column;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
}

.box-rekomendasi .row:nth-child(2n+2) .col:nth-child(1) {
    -webkit-order: 2;
    order: 2;
}

.box-rekomendasi .row:nth-child(2n+2) .col:nth-child(2) {
    -webkit-order: 1;
    order: 1;
}

.box-rekomendasi .row .col img {
    width: 100%;
}


/* footer
----------------------------------------------------------------------------------------------*/

footer .section-copyright {
    flex-direction: column;
}

.inner-content .grid-view .box--grid .box--column.callus img {
    margin-bottom: -7px;
}


/*For 480 >============================================================================================= */


/*@media only screen and (min-width:0px) and (max-width: 1330px){
    .inner-content .grid-view .box--grid .box--column.callus img {margin-bottom: -4px;}
}*/

@media only screen and (min-width: 641px) and (max-width: 767px) {
    .home .slider-wrap .bx-wrapper .bx-viewport,
    .product .slider-wrap .bx-wrapper .bx-viewport,
    .videofoto .slider-wrap .bx-wrapper .bx-viewport,
    .news .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 310px !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 640px) {
    .home .slider-wrap .bx-wrapper .bx-viewport,
    .product .slider-wrap .bx-wrapper .bx-viewport,
    .videofoto .slider-wrap .bx-wrapper .bx-viewport,
    .news .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 240px !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .grid-view .box--grid .box--column.box--min-height {
        min-height: 640px;
    }
    .inner-content {
        padding-left: 55px;
        padding-right: 55px;
    }
}

@media only screen and (max-width: 480px) {
    .grid-view .box--grid .box--column.box--min-height {
        min-height: 375px;
    }
    .inner-content {
        padding-left: 55px;
    }
}

@media only screen and (min-width: 361px) and (max-width: 480px) {
    .home .slider-wrap .bx-wrapper .bx-viewport,
    .product .slider-wrap .bx-wrapper .bx-viewport,
    .videofoto .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 190px !important;
    }
    .news .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 180px !important;
    }
}

@media only screen and (min-width: 361px) {
    .wd-xs-75 {
        max-width: 75%;
    }
    .sidebar-right {
        bottom: 7vh;
    }
}

@media only screen and (max-width: 360px) {
    .home .slider-wrap .bx-wrapper .bx-viewport,
    .product .slider-wrap .bx-wrapper .bx-viewport,
    .videofoto .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 145px !important;
    }
    .news .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 135px !important;
    }
    .sidebar-right {
        bottom: 5vh;
    }
    .image.image-full img {
        width: auto;
    }
    .grid-view .box--grid .customer.connect .box--column-wrapper .icon {
        display: none;
    }
    footer .grid {
        grid-template-columns: 2fr 2fr;
        grid-template-rows: auto auto 0px 10px 10px;
        grid-gap: 60px 10px;
        grid-auto-flow: row;
    }
}

@media only screen and (min-width:567px) and (max-width: 600px) {
    .grid-view .box--grid .box--column.fund.testi .pager-info {
        bottom: 60.5%;
    }
    .grid-view .box--grid .box--column.fund .text-money span {
        line-height: 25px;
    }
}

@media only screen and (min-width:600px) and (max-width: 668px) {
    .grid-view .box--grid .box--column.fund.testi .pager-info {
        bottom: 66.5%;
    }
    .grid-view .box--grid .box--column.fund .text-money span {
        line-height: 25px;
    }
}

@media only screen and (max-width:479px) {
    .inner-content.product-detail p {
        word-wrap: break-word;
    }
}